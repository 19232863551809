.faq {
	padding-top: 100px;
}

.faq__sub-heading {
	max-width: 100%;
}

@media (min-width: 768px) {
	.faq__sub-heading {
		max-width: 350px;
	}

	.faq {
		padding-bottom: 70px;
	}
}
