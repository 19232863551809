.mailing_list {
	padding-top: 75px;
	padding-bottom: 75px;
	color: #fff;
	background-image: linear-gradient(
		225deg,
		#fbc478 0%,
		#d371cc 50%,
		#7c7bd8 100%
	);
}

.mailing_list__container {
	color: #29343e !important;
	max-width: 500px;
}

.input_button {
	background-color: white;
	margin: auto;
	display: flex;
	justify-content: space-between;
	border-radius: 10px;
	height: 50px;
	width: 90%;
}

.input {
	border: none;
	outline: none;
	margin: 0 0 0 10px;
	width: 60%;
}
.button {
	background-color: #29343e !important;
	border: none;
	outline: none;
	width: 40%;
	color: white;
	margin: 5px 5px 5px 0 !important;
	border-radius: 10px;
}
