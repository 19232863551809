.panel__section {
	padding-top: 75px;
	padding-bottom: 75px;
}

.section {
	background-color: #29343e;
}

.heading {
	color: white;
}
