.faq-panel {
	margin-bottom: 40px;
	padding-bottom: 20px;
	cursor: pointer;
	width: 100%;
}

.faq-panel__number {
	font-weight: bold;
	width: 45px;
	color: grey;
}

.faq_panel__content-question-title {
	color: grey;
	font-weight: 400;
	margin-bottom: 0;
	font-size: 24px !important;
	text-decoration: none;
}

.faq-panel:hover .faq_panel__content-question-title,
.faq-panel:hover .faq-panel__number,
.faq-panel.open .faq_panel__content-question-title {
	color: black;
}

.faq_panel__content-question-title:hover {
	text-decoration: underline;
}

.faq_panel__content {
	display: flex;
	flex-direction: column;
	flex: 1;
	margin-top: -7px;
}

.faq_panel__content-question-icon {
	flex: 1 1 auto;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.faq_panel__content-question {
	display: flex;
}

.faq_panel__content-answer {
	font-size: 14px;
	margin-bottom: 0;
}

@media (min-width: 768px) {
	.faq_panel__content-question-title {
		font-size: 30px;
	}
}
