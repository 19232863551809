.header {
	position: relative;
	color: #fff;
	background-image: linear-gradient(
		225deg,
		#fbc478 0%,
		#d371cc 50%,
		#7c7bd8 100%
	);
}

.roundedBox {
	height: fit-content;
	width: 90%;
	background-color: #f2f2f2;
	border-radius: 20px;
	position: relative;
	top: -50px;
	border-radius: 20px;
	box-shadow: 0px +30px 50px #d1d1d1;
	margin: 0 auto;
}

@media (max-width: 1200px) {
	.roundedBox {
		top: -70px;
	}
}

.header_button {
	border-radius: 10px;
	border-color: #d1d1d1;
	border: 1px solid rgb(215, 215, 215);
	background-color: #efefef;
	color: black;
}

.description {
	color: #6d6d6d;
	font-size: 12px;
}

.header_button:hover *,
.header_button:hover *:before,
.header_button:hover *:after {
	color: rgb(79, 42, 136);
	fill: rgb(79, 42, 136) !important;
	border-color: rgb(79, 42, 136);
}

.header_button:hover,
.header_button:hover:before,
.header_button:hover:after {
	border-color: rgb(79, 42, 136);
	box-shadow: 0px +10px 50px #d1d1d1;
}

.reset_color_storage {
	color: white;
	cursor: pointer;
	background: none;
	border: none;
	text-decoration-line: underline;
}

.reset_color_storage:hover {
	text-decoration-line: none;
}
